import { AnyExtension } from '@tiptap/core'

import {
  BaseKit,
  Columns,
  Heading,
  History,
  FormatPainter,
  Clear,
  FontSize,
  FontFamily,
  Bold,
  Italic,
  Underline,
  Strike,
  Link,
  MoreMark,
  Color,
  Highlight,
  BulletList,
  OrderedList,
  TextAlign,
  Indent,
  LineHeight,
  TaskList,
  Blockquote,
  HorizontalRule,
  FindAndReplace,
  Printer,
} from './extensions'

export const editorExtensions: AnyExtension[] = [
  BaseKit.configure({
    placeholder: {
      showOnlyCurrent: true,
    },
  }),
  History,
  Columns,
  FormatPainter,
  Clear,
  Heading.configure({ spacer: true }),
  FontSize,
  FontFamily,
  Bold,
  Italic,
  Underline,
  Strike,
  MoreMark,
  Color.configure({ spacer: true }),
  Highlight,
  BulletList,
  OrderedList,
  TextAlign.configure({ types: ['heading', 'paragraph', 'image'], spacer: true }),
  Indent,
  LineHeight,
  TaskList.configure({
    spacer: true,
    taskItem: {
      nested: true,
    },
  }),
  Link,
  Blockquote,
  HorizontalRule,
  FindAndReplace.configure({ spacer: true }),
  Printer,
]
