import type { FontFamilyOptions as TiptapFontFamilyOptions } from '@tiptap/extension-font-family'
import { FontFamily as TiptapFontFamily } from '@tiptap/extension-font-family'

const DEFAULT_FONT_FAMILY_NAMES = [
  'Arial',
  'Arial Black',
  'Georgia',
  'Impact',
  'Tahoma',
  'Times New Roman',
  'Verdana',
  'Courier New',
  'Lucida Console',
  'Monaco',
  'monospace',
]

export const DEFAULT_FONT_FAMILY_MAP = DEFAULT_FONT_FAMILY_NAMES.reduce(
  (obj: { [key: string]: string }, type: string) => {
    obj[type] = type
    return obj
  },
  {}
)
import { GeneralOptions } from '@/types'

import FontFamilyButton from './components/FontFamilyMenuButton.vue'

export interface FontFamilyOptions extends TiptapFontFamilyOptions, GeneralOptions<FontFamilyOptions> {
  fontFamilyMap: {
    [key: string]: string
  }
}
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontFamily: {
      /**
       * Set the text font family.
       * CSS font-size
       *
       */
      setFontFamily: (fontFamily: string) => ReturnType
      /**
       * Unset the font family
       */
      unsetFontFamily: () => ReturnType
    }
  }
}

export const FontFamily = TiptapFontFamily.extend<FontFamilyOptions>({
  name: 'fontFamily',
  addOptions() {
    return {
      ...this.parent?.(),
      types: ['textStyle'],
      fontFamilyMap: DEFAULT_FONT_FAMILY_MAP,
      button({ editor }) {
        return {
          component: FontFamilyButton,
          componentProps: {
            editor,
            disabled: !editor.can().setFontFamily(''),
            tooltip: 'Font family',
          },
        }
      },
    }
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontFamily: {
            default: null,
            parseHTML: element => element.style.fontFamily.replace(/['"]/g, ''),
            renderHTML: attributes => {
              if (!attributes.fontFamily) {
                return {}
              }
              return {
                style: `font-family: ${attributes.fontFamily}`,
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setFontFamily:
        fontFamily =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontFamily }).run()
        },
      unsetFontFamily:
        () =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontFamily: null }).removeEmptyTextStyle().run()
        },
    }
  },
})
