import { createMultipleFilesSchema, nativeEnumSchema, stringSchema, zObject } from '@/schemas'
import { FeedTypeEnum } from '@/types'
import { ACCEPTED_FILE_MIME_TYPES, ACCEPTED_FILE_TYPES, MAX_FILE_SIZE } from '@/constants'

export const baseFeedSchema = zObject({
  id: stringSchema.optional(),
  type: nativeEnumSchema(FeedTypeEnum),
})

export const feedCallNoteSchema = baseFeedSchema.extend({
  datetime: stringSchema.datetime(),
  note: stringSchema.optional(),
})

export const feedUpdateCallNoteSchema = feedCallNoteSchema.extend({
  datetime: stringSchema.datetime().optional(),
})

export const feedNoteSchema = baseFeedSchema.extend({
  note: stringSchema.optional(),
})

export const feedFileSchema = baseFeedSchema.extend({
  files: createMultipleFilesSchema({
    required: true,
    maxSize: MAX_FILE_SIZE,
    acceptedMimeTypes: ACCEPTED_FILE_MIME_TYPES,
    acceptedTypesDescription: ACCEPTED_FILE_TYPES,
  }),
})
