<script lang="ts" setup>
import { computed } from 'vue'
import type { Editor } from '@tiptap/vue-3'

import { DropdownMenuItem, Toggle, Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '@/ui'
import { ActionDropdownButton, EditorIcon, type EditorIconType } from '@/components/editor'
import { EditorItemType } from '@/types'
import { getShortcutKey } from '@/utils'

interface Props {
  editor: Editor
  disabled?: boolean
  color?: string
  maxHeight?: string | number
  icon?: EditorIconType
  tooltip?: string
  items?: EditorItemType[]
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  color: undefined,
  maxHeight: undefined,
  icon: undefined,
  tooltip: '',
  items: () => [],
})

const active = computed(() => {
  const find = props.items.find(k => k.isActive())
  if (find && !find.default) {
    return {
      ...find,
      icon: find.icon ? find.icon : props.icon,
    }
  }
  const item: EditorItemType = {
    title: props.tooltip,
    icon: props.icon,
    isActive: () => false,
  }

  return item
})
</script>

<template>
  <ActionDropdownButton :icon="icon" :tooltip="tooltip" :disabled="disabled" class="flex w-full min-w-4 flex-row gap-1">
    <TooltipProvider>
      <Tooltip v-for="(item, index) in props.items" :key="index">
        <TooltipTrigger as-child>
          <DropdownMenuItem class="p-0" @click="item.action">
            <Toggle size="sm" class="h-7 w-7 p-1" :pressed="active.title === item.title">
              <EditorIcon v-if="item.icon" :name="item.icon" />
            </Toggle>
          </DropdownMenuItem>
        </TooltipTrigger>
        <TooltipContent class="flex flex-col items-center">
          <span>{{ item.title }}</span>
          <span>
            {{ item.shortcutKeys?.map(i => getShortcutKey(i)).join(' ') }}
          </span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  </ActionDropdownButton>
</template>
