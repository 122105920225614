<script setup lang="ts">
import { computed, WritableComputedRef, useAttrs } from 'vue'
import { useVModel } from '@vueuse/core'

import { cn } from '@/utils'

const props = withDefaults(
  defineProps<{
    defaultValue?: string | number | null
    modelValue?: string | number | File | null
    type?: string
    error?: boolean
  }>(),
  {
    type: 'text',
    error: false,
    defaultValue: null,
    modelValue: null,
  }
)

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number | File | null): void
  (e: 'change', event: Event): void
}>()

const attrs = useAttrs()

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: false,
  defaultValue: props.defaultValue ?? null,
}) as WritableComputedRef<string | number | File | null | undefined>

const onFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement
  const files = target.files

  if (files && files.length > 0) {
    modelValue.value = files[0]
  } else {
    modelValue.value = null
  }

  // Emit the change event to forward it
  emits('change', event)
}

const classes = computed(() => {
  return cn(
    'flex h-10 w-full rounded-md border border-input bg-slate-100 px-3 py-2 text-sm ring-offset-background ' +
      'file:border-0 file:bg-slate-100 file:text-sm file:font-medium placeholder:text-muted-foreground ' +
      'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 ' +
      'disabled:cursor-not-allowed disabled:opacity-50',
    attrs.class as string,
    { 'border-red-500 text-red-500': props.error }
  )
})
</script>

<template>
  <input
    v-if="props.type !== 'file'"
    v-model="modelValue"
    :type="props.type"
    :class="classes"
    v-bind="attrs"
    @change="$emit('change', $event)"
  />
  <input v-else type="file" :class="classes" v-bind="attrs" @change="onFileChange" />
</template>
