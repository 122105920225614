<script setup lang="ts">
import { ref, computed } from 'vue'
import { useLocalStorage } from '@vueuse/core'

import { Popover, PopoverContent, PopoverTrigger } from '@/ui'

const DEFAULT_COLOR = '#262626'
/** Default color list for text color and text highlight */
const COLORS_LIST = [
  '#000000',
  '#262626',
  '#595959',
  '#8C8C8C',
  '#BFBFBF',
  '#D9D9D9',
  '#E9E9E9',
  '#F5F5F5',
  '#FAFAFA',
  '#FFFFFF',
  '#F5222D',
  '#FA541C',
  '#FA8C16',
  '#FADB14',
  '#52C41A',
  '#13C2C2',
  '#1890FF',
  '#2F54EB',
  '#722ED1',
  '#EB2F96',
  '#FFE8E6',
  '#FFECE0',
  '#FFEFD1',
  '#FCFCCA',
  '#E4F7D2',
  '#D3F5F0',
  '#D4EEFC',
  '#DEE8FC',
  '#EFE1FA',
  '#FAE1EB',
  '#FFA39E',
  '#FFBB96',
  '#FFD591',
  '#FFFB8F',
  '#B7EB8F',
  '#87E8DE',
  '#91D5FF',
  '#ADC6FF',
  '#D3ADF7',
  '#FFADD2',
  '#FF4D4F',
  '#FF7A45',
  '#FFA940',
  '#FFEC3D',
  '#73D13D',
  '#36CFC9',
  '#40A9FF',
  '#597EF7',
  '#9254DE',
  '#F759AB',
  '#CF1322',
  '#D4380D',
  '#D46B08',
  '#D4B106',
  '#389E0D',
  '#08979C',
  '#096DD9',
  '#1D39C4',
  '#531DAB',
  '#C41D7F',
  '#820014',
  '#871400',
  '#873800',
  '#614700',
  '#135200',
  '#00474F',
  '#003A8C',
  '#061178',
  '#22075E',
  '#780650',
] as const

interface Props {
  modelValue?: string
  highlight?: boolean
  disabled?: boolean
}

interface Emits {
  (event: 'update:modelValue', color: string | undefined): void
  (event: 'change', color: string | undefined): void
}
const chunkedColors = COLORS_LIST.reduce((acc, color, index) => {
  const chunkIndex = Math.floor(index / 10)
  if (!acc[chunkIndex]) acc[chunkIndex] = []
  acc[chunkIndex].push(color)
  return acc
}, [] as string[][])

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  highlight: false,
  disabled: false,
})
const html5Color = ref<HTMLInputElement | null>(null)

const recentColorsStore = useLocalStorage<string[]>(props.highlight ? 'recentColorsHighlight' : 'recentColors', [])

const emit = defineEmits<Emits>()

function setRecentColor(color: string) {
  const index = recentColorsStore.value.indexOf(color)
  if (index !== -1) {
    recentColorsStore.value.splice(index, 1)
  }
  recentColorsStore.value.unshift(color)
  if (recentColorsStore.value.length > 10) {
    recentColorsStore.value.pop()
  }
}
const recentColors = computed(() => {
  return recentColorsStore.value.slice(0, 10)
})

function setColor(color: string | undefined) {
  if (color === undefined) {
    // clear color
    emit('update:modelValue', color)
    emit('change', color)
    return
  }
  // check if color is correct
  const isCorrectColor = /^#([0-9A-F]{3}){1,2}$/i.test(color)
  if (isCorrectColor) {
    emit('update:modelValue', color)
    emit('change', color)
    setRecentColor(color)
  }
}
const triggerHtml5Color = () => {
  html5Color.value?.click()
}
const handleColorChange = (e: Event) => {
  const input = e.target as HTMLInputElement
  setColor(input.value)
}
</script>

<template>
  <Popover>
    <PopoverTrigger :disabled="disabled">
      <slot />
    </PopoverTrigger>
    <PopoverContent hide-when-detached class="h-full w-full p-2" align="start" side="bottom">
      <div class="flex flex-col">
        <!-- Hightlight -->
        <div
          v-if="highlight"
          class="rd-1 flex cursor-pointer items-center p-1 hover:bg-accent"
          @click="setColor(undefined)"
        >
          <span
            class="relative inline-block h-6 w-6 cursor-pointer rounded-sm border p-0.5 after:absolute after:left-0 after:top-[10px] after:block after:h-0 after:w-6 after:rotate-[45deg] after:border-b-2 after:border-b-red-500 hover:border-border hover:shadow-sm"
          >
            <span style="background-color: transparent">
              <svg viewBox="0 0 18 18" style="fill: rgba(0, 0, 0, 0.4); display: none">
                <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path>
              </svg>
            </span>
          </span>
          <span class="ml-1 text-sm">Remove Color</span>
        </div>
        <!-- Color -->
        <div v-else class="rd-1 flex cursor-pointer items-center p-1 hover:bg-accent" @click="setColor(undefined)">
          <span class="inline-block h-6 w-6 cursor-pointer rounded-sm border border-transparent p-0.5">
            <span
              :style="`background-color: ${DEFAULT_COLOR}`"
              class="relative block h-[18px] w-[18px] rounded-[2px] border-transparent"
            >
              <svg viewBox="0 0 18 18" style="fill: rgb(255, 255, 255); display: none">
                <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path>
              </svg>
            </span>
          </span>
          <span class="ml-1 text-sm">Default</span>
        </div>

        <span v-for="(items, index) in chunkedColors" :key="index" class="relative flex h-auto w-full p-0 last:pb-2">
          <span
            v-for="(item, i) in items"
            :key="i"
            class="flex-[0 0 auto] inline-block h-6 w-6 cursor-pointer rounded-sm border border-transparent p-0.5 hover:border-border hover:shadow-sm"
            @click="setColor(item)"
          >
            <span
              :style="{ backgroundColor: item }"
              class="relative block h-[18px] w-[18px] rounded-[2px] border-transparent"
            >
              <svg v-if="item !== modelValue" viewBox="0 0 18 18" style="fill: rgb(255, 255, 255); display: none">
                <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path>
              </svg>
              <svg
                v-else
                class="absolute left-[1px] top-[-1px] h-3 w-3"
                viewBox="0 0 18 18"
                style="fill: rgb(255, 255, 255); display: block"
              >
                <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path>
              </svg>
            </span>
          </span>
        </span>
        <div v-show="recentColors.length">
          <div class="my-1 text-sm">Recently Used</div>
          <span class="relative flex h-auto w-full p-0 last:pb-2">
            <span
              v-for="(item, index) in recentColors"
              :key="index"
              class="flex-[0 0 auto] inline-block h-6 w-6 cursor-pointer rounded-sm border border-transparent p-0.5 hover:border-border hover:shadow-sm"
              @click="setColor(item)"
            >
              <span
                class="relative block h-[18px] w-[18px] rounded-[2px] border-transparent"
                :style="{ backgroundColor: item }"
              >
                <svg viewBox="0 0 18 18" style="fill: rgb(255, 255, 255); display: none">
                  <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"></path>
                </svg>
              </span>
            </span>
          </span>
        </div>
        <div class="relative">
          <div class="px-1.5 py-1.5 text-sm hover:cursor-pointer hover:bg-accent" @click="triggerHtml5Color">
            Color Picker...
          </div>
          <input
            ref="html5Color"
            type="color"
            class="absolute left-0 top-4"
            style="visibility: hidden"
            @change="handleColorChange"
          />
        </div>
      </div>
    </PopoverContent>
  </Popover>
</template>
