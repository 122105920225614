<script setup lang="ts">
import type { Editor } from '@tiptap/vue-3'

import { Popover, PopoverContent, PopoverTrigger } from '@/ui'
import { ButtonViewReturnComponentProps } from '@/types'
import { ActionButton, EditorIconType } from '@/components/editor'

import LinkEditBlock from './LinkEditBlock.vue'

interface Props {
  editor: Editor
  icon?: EditorIconType
  title?: string
  tooltip?: string
  disabled?: boolean
  shortcutKeys?: string[]
  isActive?: ButtonViewReturnComponentProps['isActive']
  action?: ButtonViewReturnComponentProps['action']
}
const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  title: undefined,
  tooltip: undefined,
  disabled: false,
  shortcutKeys: undefined,
  action: undefined,
  isActive: undefined,
})

function onSetLink(link: string, text?: string, openInNewTab?: boolean) {
  if (props.action) {
    props.action({ link, text, openInNewTab })
  }
}
</script>

<template>
  <Popover>
    <PopoverTrigger :disabled="disabled">
      <ActionButton :icon="icon" :tooltip="tooltip" :is-active="isActive" :disabled="disabled" />
    </PopoverTrigger>
    <PopoverContent hide-when-detached as-child class="w-full" align="start" side="bottom">
      <LinkEditBlock :editor="editor" @on-set-link="onSetLink" />
    </PopoverContent>
  </Popover>
</template>
