<script lang="ts" setup>
import { computed } from 'vue'
import { Editor, getMarkAttributes } from '@tiptap/vue-3'

import { DropdownMenuCheckboxItem, DropdownMenuSeparator } from '@/ui'
import { EditorItemType } from '@/types'
import { ActionDropdownButton } from '@/components/editor'

interface Props {
  editor: Editor
  disabled?: boolean
  color?: string
  shortcutKeys?: string[]
  maxHeight?: string | number
  tooltip?: string
  items?: EditorItemType[]
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  color: undefined,
  maxHeight: undefined,
  tooltip: '',
  shortcutKeys: undefined,
  items: () => [],
})

const fontFamilyOptions = computed(() => {
  const fontFamilyOptions = props.editor.extensionManager.extensions.find(e => e.name === 'fontFamily')!.options
  return {
    ['Default']: 'Default',
    ...fontFamilyOptions.fontFamilyMap,
  }
})
const activeFontFamily = computed(() => {
  return getMarkAttributes(props.editor.state, 'textStyle').fontFamily || 'Default'
})
function toggleFontType(name: string) {
  if (name === activeFontFamily.value) {
    props.editor.commands.unsetFontFamily()
  } else {
    props.editor.commands.setFontFamily(name)
  }
}
</script>

<template>
  <ActionDropdownButton :disabled="disabled" :tooltip="tooltip" :title="activeFontFamily" btn-class="min-w-24 max-w-32">
    <template v-for="(item, index) in fontFamilyOptions" :key="index">
      <DropdownMenuCheckboxItem :checked="activeFontFamily === item" @click="toggleFontType(item)">
        <span class="ml-1 h-full" :data-font="item" :style="{ fontFamily: item ?? undefined }">
          {{ item }}
        </span>
      </DropdownMenuCheckboxItem>
      <DropdownMenuSeparator v-if="item === 'Default'" />
    </template>
  </ActionDropdownButton>
</template>
