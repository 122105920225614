import { Node } from '@tiptap/core'

import { ActionButton } from '@/components/editor'
import { GeneralOptions } from '@/types'

export type ClearOptions = GeneralOptions<ClearOptions>

export const Clear = Node.create<ClearOptions>({
  name: 'clear',
  addOptions() {
    return {
      ...this.parent?.(),
      button: ({ editor }) => ({
        component: ActionButton,
        componentProps: {
          action: () => editor.chain().focus().clearNodes().unsetAllMarks().run(),
          disabled: !editor.can().chain().focus().clearNodes().unsetAllMarks().run(),
          icon: 'Eraser',
          tooltip: 'Clear Format',
        },
      }),
    }
  },
})
