import { Extension } from '@tiptap/core'
import type { Editor, Command } from '@tiptap/core'
import { TextSelection, AllSelection, EditorState, Transaction } from '@tiptap/pm/state'
import { Node as ProsemirrorNode, NodeType } from '@tiptap/pm/model'

import { GeneralOptions } from '@/types'

import LineHeightDropdown from './components/LineHeightDropdown.vue'

export interface LineHeightOptions extends GeneralOptions<LineHeightOptions> {
  types: string[]
  lineHeights: string[]
  defaultHeight: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    lineHeight: {
      setLineHeight: (lineHeight: string) => ReturnType
      unsetLineHeight: () => ReturnType
    }
  }
}

export const DEFAULT_LINE_HEIGHT = '1'
export const LINE_HEIGHT_100 = 1.7
export const ALLOWED_NODE_TYPES = ['paragraph', 'heading', 'list_item', 'todo_item']

export function isLineHeightActive(state: EditorState, lineHeight: string): boolean {
  const { selection, doc } = state
  const { from, to } = selection

  let keepLooking = true
  let active = false

  doc.nodesBetween(from, to, node => {
    const nodeType = node.type
    const lineHeightValue = node.attrs.lineHeight || DEFAULT_LINE_HEIGHT

    if (ALLOWED_NODE_TYPES.includes(nodeType.name)) {
      if (keepLooking && lineHeight === lineHeightValue) {
        keepLooking = false
        active = true

        return false
      }
      return nodeType.name !== 'list_item' && nodeType.name !== 'todo_item'
    }
    return keepLooking
  })

  return active
}

interface SetLineHeightTask {
  node: ProsemirrorNode
  nodeType: NodeType
  pos: number
}

export function setTextLineHeight(tr: Transaction, lineHeight: string | null): Transaction {
  const { selection, doc } = tr

  if (!selection || !doc) return tr

  if (!(selection instanceof TextSelection || selection instanceof AllSelection)) {
    return tr
  }

  const { from, to } = selection

  const tasks: Array<SetLineHeightTask> = []
  const lineHeightValue = lineHeight && lineHeight !== DEFAULT_LINE_HEIGHT ? lineHeight : null

  doc.nodesBetween(from, to, (node, pos) => {
    const nodeType = node.type
    if (ALLOWED_NODE_TYPES.includes(nodeType.name)) {
      const lineHeight = node.attrs.lineHeight || null
      if (lineHeight !== lineHeightValue) {
        tasks.push({
          node,
          pos,
          nodeType,
        })
      }
      return nodeType.name !== 'list_item' && nodeType.name !== 'todo_item'
    }
    return true
  })

  if (!tasks.length) return tr

  tasks.forEach(task => {
    const { node, pos, nodeType } = task
    let { attrs } = node

    attrs = {
      ...attrs,
      lineHeight: lineHeightValue,
    }

    tr = tr.setNodeMarkup(pos, nodeType, attrs, node.marks)
  })

  return tr
}

export function createLineHeightCommand(lineHeight: string): Command {
  return ({ state, dispatch }) => {
    const { selection } = state
    let { tr } = state
    tr = tr.setSelection(selection)

    tr = setTextLineHeight(tr, lineHeight)

    if (tr.docChanged) {
      if (dispatch) dispatch(tr)
      return true
    }

    return false
  }
}

export const LineHeight = Extension.create<LineHeightOptions>({
  name: 'lineHeight',
  addOptions() {
    return {
      ...this.parent?.(),
      types: ['paragraph', 'heading', 'list_item', 'todo_item'],
      lineHeights: ['100%', '115%', '150%', '200%', '250%', '300%'],
      defaultHeight: DEFAULT_LINE_HEIGHT,
      button({ editor }: { editor: Editor }) {
        return {
          component: LineHeightDropdown,
          componentProps: {
            editor,
            icon: 'LineHeight',
            tooltip: 'Line Height',
          },
        }
      },
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineHeight: {
            default: null,
            parseHTML: element => {
              return element.style.lineHeight || this.options.defaultHeight
            },
            renderHTML: attributes => {
              if (attributes.lineHeight === this.options.defaultHeight || !attributes.lineHeight) {
                return {}
              }
              return { style: `line-height: ${attributes.lineHeight}` }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setLineHeight: lineHeight => createLineHeightCommand(lineHeight),
      unsetLineHeight:
        () =>
        ({ commands }) => {
          return this.options.types.every(type => commands.resetAttributes(type, 'lineHeight'))
        },
    }
  },
})
