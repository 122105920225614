import { Extension } from '@tiptap/core'
import { storeToRefs } from 'pinia'

import type { GeneralOptions } from '@/types'
import { useEditorStore } from '@/stores'
import { ActionButton } from '@/components/editor'
export type PrinterOptions = GeneralOptions<PrinterOptions>

export const Printer = Extension.create<PrinterOptions>({
  name: 'printer',
  addOptions() {
    return {
      ...this.parent?.(),
      button: () => {
        const editorStore = useEditorStore()
        const { printer } = storeToRefs(editorStore)
        const { togglePrinter } = editorStore

        return {
          component: ActionButton,
          componentProps: {
            tooltip: 'Print',
            action: () => {
              togglePrinter()
            },
            icon: 'Printer',
            shortcutKeys: ['mod', 'P'],
            isActive: () => printer.value,
          },
        }
      },
    }
  },
})
