<script setup lang="ts">
import { reactive, watchEffect, ref } from 'vue'
import type { Editor } from '@tiptap/vue-3'
import { onClickOutside, useFocus, MaybeRef } from '@vueuse/core'

import { Input, Label, Button, Icon, Checkbox } from '@/ui'

interface Props {
  editor: Editor
}
const props = withDefaults(defineProps<Props>(), {})
const emits = defineEmits(['onSetLink', 'onClickOutside'])

let form = reactive({
  text: '',
  link: '',
})

const inputRef = ref<HTMLInputElement | null>(null)
useFocus(inputRef as MaybeRef, {
  initialValue: true,
})
const openInNewTab = ref<boolean>(false)
const target = ref(null)
onClickOutside(target, event => emits('onClickOutside', event))

watchEffect(() => {
  const { href: link, target } = props.editor.getAttributes('link')
  const { selection, doc } = props.editor.state
  const { from, to } = selection
  const text = doc.textBetween(from, to, ' ')
  form.link = link
  form.text = text
  openInNewTab.value = target === '_blank'
})

function handleSubmit() {
  emits('onSetLink', form.link, form.text, openInNewTab.value)
}
</script>

<template>
  <div
    ref="target"
    class="rounded-lg border border-neutral-200 bg-white p-2 shadow-sm dark:border-neutral-800 dark:bg-black"
  >
    <form class="flex flex-col gap-2" @submit.prevent="handleSubmit">
      <Label>Text</Label>
      <div class="flex w-full max-w-sm items-center gap-1.5">
        <div class="relative w-full max-w-sm items-center">
          <Input v-model="form.text" type="text" required class="w-80" placeholder="输入文本" />
        </div>
      </div>
      <Label>Link</Label>
      <div class="flex w-full max-w-sm items-center gap-1.5">
        <div class="relative w-full max-w-sm items-center">
          <Input ref="inputRef" v-model="form.link" type="url" required class="pl-10" />
          <span class="absolute inset-y-0 start-0 flex items-center justify-center px-2">
            <Icon class="size-5 text-muted-foreground" name="LinkIcon" />
          </span>
        </div>
      </div>
      <div class="mt-1 flex items-center space-x-2">
        <Checkbox id="openInNewTab" v-model:checked="openInNewTab" />
        <Label for="openInNewTab">Open In New Tab</Label>
      </div>
      <Button type="submit" class="mt-2 self-end">Apply</Button>
    </form>
  </div>
</template>
