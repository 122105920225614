<script lang="ts" setup>
import { computed } from 'vue'
import type { Editor } from '@tiptap/vue-3'

import { DropdownMenuCheckboxItem, DropdownMenuSeparator, DropdownMenuShortcut } from '@/ui'
import { getShortcutKey } from '@/utils'
import { ActionDropdownButton } from '@/components/editor'

import { HeadingItem } from './types'

interface Props {
  editor: Editor
  disabled?: boolean
  color?: string
  shortcutKeys?: string[]
  maxHeight?: string | number
  tooltip?: string
  items?: HeadingItem[]
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  color: undefined,
  maxHeight: undefined,
  tooltip: '',
  shortcutKeys: undefined,
  items: () => [],
})

const active = computed(() => {
  const find = props.items.find((k: HeadingItem) => k.isActive())
  if (find && !find.default) {
    return {
      ...find,
    }
  }
  const item: HeadingItem = {
    title: props.tooltip,
    level: 0,
    isActive: () => false,
  }
  return item
})
</script>

<template>
  <ActionDropdownButton :disabled="disabled" :tooltip="tooltip" :title="active?.title" btn-class="min-w-24 max-w-32">
    <template v-for="(item, index) in props.items" :key="index">
      <DropdownMenuCheckboxItem :checked="active.title === item.title" @click="item.action">
        <div class="ml-1 h-full" :class="`heading-` + item.level">
          {{ item.title }}
        </div>
        <DropdownMenuShortcut class="pl-4">
          {{ item.shortcutKeys?.map(sk => getShortcutKey(sk)).join(' ') }}
        </DropdownMenuShortcut>
      </DropdownMenuCheckboxItem>
      <DropdownMenuSeparator v-if="item.level === 0" />
    </template>
  </ActionDropdownButton>
</template>
