<script lang="ts" setup>
import { computed } from 'vue'
import type { Editor } from '@tiptap/vue-3'

import { DropdownMenuCheckboxItem, DropdownMenuShortcut } from '@/ui'
import { EditorItemType } from '@/types'
import { getShortcutKeys } from '@/utils'
import { EditorIcon, EditorIconType, ActionDropdownButton } from '@/components/editor'

interface Props {
  editor: Editor
  disabled?: boolean
  color?: string
  maxHeight?: string | number
  icon?: EditorIconType
  tooltip?: string
  items?: EditorItemType[]
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  color: undefined,
  maxHeight: undefined,
  icon: undefined,
  tooltip: '',
  items: () => [],
})

const active = computed(() => {
  const find = props.items.find(k => k.isActive())
  if (find && !find.default) {
    return {
      ...find,
      icon: find.icon ? find.icon : props.icon,
    }
  }
  const item: EditorItemType = {
    title: props.tooltip,
    icon: props.icon,
    isActive: () => false,
  }

  return item
})
</script>

<template>
  <ActionDropdownButton :icon="icon" :tooltip="tooltip" :disabled="disabled">
    <DropdownMenuCheckboxItem
      v-for="(item, index) in props.items"
      :key="index"
      :checked="active.title === item.title"
      :disabled="item.disabled"
      class="flex items-center gap-3"
      @click="item.action"
    >
      <EditorIcon v-if="item.icon" :name="item.icon" />
      <span class="ml-1">{{ item.title }}</span>
      <DropdownMenuShortcut v-if="item.shortcutKeys" class="ml-auto">
        {{ getShortcutKeys(item.shortcutKeys) }}
      </DropdownMenuShortcut>
    </DropdownMenuCheckboxItem>
  </ActionDropdownButton>
</template>
