import { Extension } from '@tiptap/core'

import { GeneralOptions } from '@/types'

import TextDropdown from './TextDropdown.vue'

export type TextBubbleOptions = GeneralOptions<TextBubbleOptions>

export const TextBubble = Extension.create<TextBubbleOptions>({
  name: 'text-bubble',
  addOptions() {
    return {
      ...this.parent?.(),
      toolbar: false,
      button: () => ({
        component: TextDropdown,
        componentProps: {},
      }),
    }
  },
})

export default TextBubble
