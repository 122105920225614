import type { Editor } from '@tiptap/core'

let isMacResult: boolean | undefined
/**
 * Return true if the user is using a Mac (as opposed to Windows, etc.) device.
 */
export function isMac(): boolean {
  if (isMacResult === undefined) {
    isMacResult = navigator.userAgent?.includes('Mac')
  }
  return isMacResult
}

export function getShortcutKey(key: string): string {
  if (key.toLowerCase() === 'mod') {
    return isMac() ? '⌘' : 'Ctrl'
  } else if (key.toLowerCase() === 'alt') {
    return isMac() ? '⌥' : 'Alt'
  } else if (key.toLowerCase() === 'shift') {
    return isMac() ? '⇧' : 'Shift'
  } else {
    return key
  }
}

export function getShortcutKeys(keys: string[]): string {
  return keys.map(getShortcutKey).join(' ')
}

export function getSelectionText(editor: Editor): string {
  const { from, to, empty } = editor.state.selection
  if (empty) {
    return ''
  }
  return editor.state.doc.textBetween(from, to, '')
}
