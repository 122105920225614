<script lang="ts" setup>
import { computed } from 'vue'
import type { Editor } from '@tiptap/vue-3'

import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider, DropdownMenuItem } from '@/ui'
import { ActionDropdownButtonSplit } from '@/components/editor'

interface BulletListOption {
  label: string
  value: 'disc' | 'circle' | 'square'
}

interface Props {
  editor: Editor
  disabled?: boolean
  tooltip?: string
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  tooltip: '',
})

const BulletListOptions: BulletListOption[] = [
  { label: 'Disc', value: 'disc' },
  { label: 'Circle', value: 'circle' },
  { label: 'Square', value: 'square' },
]

const active = computed((): 'disc' | 'circle' | 'square' => {
  if (props.editor?.isActive('bulletList')) {
    return props.editor.getAttributes('bulletList').listStyleType as 'disc' | 'circle' | 'square'
  }
  return 'disc'
})

function toggleBulletList(item: BulletListOption): void {
  if (props.editor.isActive('bulletList')) {
    if (props.editor.getAttributes('bulletList').listStyleType === item.value) {
      props.editor.chain().focus().toggleBulletList().run()
    } else {
      props.editor.chain().focus().updateAttributes('bulletList', { listStyleType: item.value }).run()
    }
  } else {
    props.editor.chain().focus().toggleBulletList().updateAttributes('bulletList', { listStyleType: item.value }).run()
  }
}
</script>

<template>
  <ActionDropdownButtonSplit
    :action="toggleBulletList"
    :disabled="disabled"
    :tooltip="tooltip"
    class="grid w-full min-w-4 grid-cols-3 gap-1"
  >
    <TooltipProvider>
      <Tooltip v-for="item in BulletListOptions" :key="item.value" :delay-duration="0">
        <TooltipTrigger>
          <DropdownMenuItem class="p-0" @click="toggleBulletList(item)">
            <div
              :class="[active === item.value ? 'border border-accent-foreground bg-accent' : '']"
              class="box-border flex h-[48px] w-[48px] flex-col rounded-sm border"
            >
              <ol
                :style="{ listStyleType: item.value, lineHeight: 1 }"
                class="flex flex-1 list-outside flex-col items-center justify-center pl-3 text-[10px]"
              >
                <li v-for="i in 3" :key="i">
                  <hr class="my-1 h-[3px] w-6 border-0 bg-gray-200" />
                </li>
              </ol>
            </div>
          </DropdownMenuItem>
        </TooltipTrigger>
        <TooltipContent side="bottom" class="bg-primary text-xs text-primary-foreground">
          {{ item.label }}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  </ActionDropdownButtonSplit>
</template>
