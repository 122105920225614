<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { ScrollAreaCorner, ScrollAreaRoot, type ScrollAreaRootProps, ScrollAreaViewport } from 'radix-vue'

import { cn } from '@/utils'

import ScrollBar from './ScrollBar.vue'

const props = defineProps<ScrollAreaRootProps & { class?: HTMLAttributes['class'] }>()
const emits = defineEmits(['scroll'])

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const handleScroll = (event: Event) => {
  emits('scroll', event)
}
</script>

<template>
  <ScrollAreaRoot v-bind="delegatedProps" :class="cn('relative overflow-hidden', props.class)">
    <ScrollAreaViewport class="h-full w-full rounded-[inherit]" @scroll="handleScroll">
      <slot />
    </ScrollAreaViewport>
    <ScrollBar />
    <ScrollAreaCorner />
  </ScrollAreaRoot>
</template>
