<script setup lang="ts">
import { computed } from 'vue'

import { iconLibs, IconType } from '@/types'

const props = withDefaults(defineProps<IconType>(), {
  lib: 'outline',
})

const IconComponent = computed(() => {
  return iconLibs[props.lib][props.name]
})
</script>

<template>
  <component :is="IconComponent" v-if="IconComponent" v-bind="$attrs" />
</template>
