<script setup lang="ts">
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger, Button } from '@/ui'
import { cn } from '@/utils'
import { ButtonViewReturnComponentProps } from '@/types'
import { EditorIcon, EditorIconType } from '@/components/editor'

import ActionButton from './ActionButton.vue'

interface Props {
  icon?: EditorIconType
  title?: string
  tooltip?: string
  disabled?: boolean
  class?: string
  shortcutKeys?: string[]
  btnClass?: string
  action?: ButtonViewReturnComponentProps['action']
  isActive?: ButtonViewReturnComponentProps['isActive']
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  title: undefined,
  tooltip: undefined,
  disabled: false,
  action: undefined,
  isActive: undefined,
  shortcutKeys: undefined,
  class: '',
  btnClass: '',
})
</script>

<template>
  <div class="flex h-[32px] items-center rounded-md hover:bg-muted" :class="[isActive?.() && 'bg-muted']">
    <ActionButton
      :class="btnClass"
      :action="action"
      :title="title"
      :icon="icon"
      :tooltip="tooltip"
      :disabled="disabled"
      :shortcut-keys="shortcutKeys"
    ></ActionButton>
    <DropdownMenu>
      <DropdownMenuTrigger :disabled="disabled">
        <Button
          variant="ghost"
          size="icon"
          class="h-[32px] w-3 rounded-l-none hover:bg-muted-foreground/20"
          :disabled="disabled"
        >
          <EditorIcon class="h-3 w-3 text-zinc-500" name="MenuDown" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent :class="cn('min-w-24', props.class)" align="start" side="bottom" v-bind="$attrs">
        <slot />
      </DropdownMenuContent>
    </DropdownMenu>
  </div>
</template>
