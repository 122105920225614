<script lang="ts" setup>
import type { TooltipContentProps } from 'radix-vue'
import type { Editor } from '@tiptap/core'
import type { HTMLAttributes } from 'vue'

import { Toggle, Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '@/ui'
import { ButtonViewReturnComponentProps } from '@/types'
import { EditorIcon, type EditorIconType } from '@/components/editor'
import { getShortcutKey } from '@/utils'

interface Props {
  icon?: EditorIconType
  title?: string
  tooltip?: string
  disabled?: boolean
  shortcutKeys?: string[]
  customClass?: string
  loading?: boolean
  tooltipOptions?: TooltipContentProps
  color?: string
  action?: ButtonViewReturnComponentProps['action']
  isActive?: ButtonViewReturnComponentProps['isActive']
  editor?: Editor
  class?: HTMLAttributes['class']
}
withDefaults(defineProps<Props>(), {
  icon: undefined,
  title: undefined,
  tooltip: undefined,
  disabled: false,
  customClass: '',
  color: undefined,
  loading: false,
  shortcutKeys: undefined,
  tooltipOptions: undefined,
  action: undefined,
  isActive: undefined,
  editor: undefined,
  class: '',
})
</script>

<template>
  <TooltipProvider>
    <Tooltip :delay-duration="0">
      <TooltipTrigger>
        <Toggle
          size="sm"
          :pressed="isActive?.() || false"
          :disabled="disabled"
          :class="['h-[32px]', customClass, title ? 'w-auto' : 'w-[32px]']"
          type="button"
          @click="action"
        >
          <div v-if="loading">
            <EditorIcon class="animate-spin" name="LoaderCircle" />
          </div>
          <div v-else class="flex items-center gap-1">
            <EditorIcon v-if="icon" :name="icon" />
            <slot name="icon"></slot>
          </div>
          <div v-if="title" class="ml-1 font-normal">{{ title }}</div>
          <slot></slot>
        </Toggle>
      </TooltipTrigger>
      <TooltipContent v-if="tooltip" v-bind="tooltipOptions" class="bg-primary text-xs text-primary-foreground">
        <div class="flex max-w-24 flex-col items-center text-center">
          <div>{{ tooltip }}</div>
          <div v-if="shortcutKeys && shortcutKeys.length" style="display: flex; gap: 4px">
            <span v-for="(shortcutKey, index) in shortcutKeys" :key="index">
              {{ getShortcutKey(shortcutKey) }}
            </span>
          </div>
        </div>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
</template>
