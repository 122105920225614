import { z, ZodTypeAny, ZodError } from 'zod'

const zObject = <T extends z.ZodRawShape>(ZodRawShape: T) => z.object(ZodRawShape)

const arraySchema = <T extends ZodTypeAny>(val: T) => z.array(val)

const unionSchema = <T extends [ZodTypeAny, ZodTypeAny, ...ZodTypeAny[]]>(types: T) => z.union(types)

const stringSchema = z.string()

const idSchema = stringSchema.ulid()

const numberSchema = z.number()

const literalSchema = <T extends z.Primitive>(val: T) => z.literal(val)

const booleanSchema = z.boolean()

const booleanFromStringSchema = stringSchema.transform(value =>
  ['true', 'yes', '1', 'on'].includes(value.toLowerCase())
)

const secretSchema = stringSchema.min(15)

const urlSchema = stringSchema.url()

const enumSchema = <T extends [string, ...string[]]>(enumArr: T) => z.enum(enumArr)

type FileOptions = {
  maxSize: number
  acceptedMimeTypes: string[]
  acceptedTypesDescription: string
  required?: boolean
  maxFiles?: number
}

const createFileSchema = (options: FileOptions) => {
  const { maxSize, acceptedMimeTypes, acceptedTypesDescription, required = false } = options
  // Base schema to handle single file input
  let schema: z.ZodType<File | undefined> = z
    .any()
    .transform(files => {
      if (files instanceof FileList && files.length > 0) {
        return files[0]
      }
      if (files instanceof File) {
        return files
      }
      return undefined // Return undefined instead of null
    })
    .refine(
      file => {
        if (!file) return !required // If not required and no file, it's valid
        return file instanceof File
      },
      { message: required ? 'File is required.' : 'Invalid file type.' }
    )
    .refine(
      file => {
        if (!file) return !required // Skip validation if not required and no file
        return file.size <= maxSize
      },
      {
        message: `Max file size is ${maxSize / 1024 / 1024} MB.`,
      }
    )
    .refine(
      file => {
        if (!file) return !required // Skip validation if not required and no file
        return acceptedMimeTypes.includes(file.type)
      },
      {
        message: acceptedTypesDescription,
      }
    )

  // Apply optional based on the 'required' flag
  if (!required) {
    schema = schema.optional() // Allow undefined
  }

  return schema
}

const createMultipleFilesSchema = (options: FileOptions) => {
  const { maxSize, acceptedMimeTypes, acceptedTypesDescription, required = false, maxFiles } = options
  // Base schema to handle multiple files input
  let schema: z.ZodType<File[] | undefined> = z
    .any()
    .optional()
    .transform(files => {
      if (files instanceof FileList) {
        return Array.from(files)
      }
      if (Array.isArray(files)) {
        return files
      }
      return undefined // Return undefined instead of empty array or null
    })
    .refine(
      files => {
        if (required) {
          return files !== undefined && files.length > 0
        }
        return true // If not required, any number of files (including 0) is valid
      },
      { message: required ? 'At least one file is required.' : undefined }
    )
    .refine(
      files => {
        if (files === undefined) return !required // If no files and not required, valid
        return files.every(file => file instanceof File)
      },
      { message: 'One or more files are invalid.' }
    )
    .refine(
      files => {
        if (files === undefined) return !required // Skip validation if not required and no files
        return files.every(file => file.size <= maxSize)
      },
      { message: `Each file must be smaller than ${maxSize / 1024 / 1024} MB.` }
    )
    .refine(
      files => {
        if (files === undefined) return !required // Skip validation if not required and no files
        return files.every(file => acceptedMimeTypes.includes(file.type))
      },
      { message: acceptedTypesDescription }
    )

  // Maximum number of files validation
  if (maxFiles !== undefined) {
    schema = schema.refine(
      files => {
        if (files === undefined) return !required // If not required and no files, valid
        return files.length <= maxFiles
      },
      { message: `You can upload up to ${maxFiles} files.` }
    )
  }

  // Apply optional based on the 'required' flag
  if (!required) {
    schema = schema.optional() // Allow undefined
  }

  return schema
}

const nativeEnumSchema = <T extends z.EnumLike>(enumArr: T, params?: z.ZodTypeDef) => z.nativeEnum(enumArr, params)

const isInstanceOfZodError = (error: unknown): error is ZodError => error instanceof ZodError

export {
  zObject,
  arraySchema,
  unionSchema,
  stringSchema,
  idSchema,
  numberSchema,
  literalSchema,
  booleanSchema,
  booleanFromStringSchema,
  secretSchema,
  urlSchema,
  enumSchema,
  createFileSchema,
  createMultipleFilesSchema,
  nativeEnumSchema,
  isInstanceOfZodError,
}

export type ZInferType<T extends ZodTypeAny> = z.infer<T>
