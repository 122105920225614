<script setup lang="ts">
import { computed } from 'vue'
import type { HTMLAttributes } from 'vue'

import { cn } from '@/utils'
import { Icon } from '@/ui'
import { type EditorIconType, editorIcons } from '@/components/editor'

const props = defineProps<{
  name: EditorIconType
  class?: HTMLAttributes['class']
}>()

const iconName = computed(() => editorIcons[props.name])
</script>

<template>
  <Icon :name="iconName" :class="cn('size-4', props.class)" />
</template>
