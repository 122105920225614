import {
  arraySchema,
  createMultipleFilesSchema,
  idSchema,
  numberSchema,
  projectFileSchema,
  stringSchema,
  unionSchema,
  zObject,
} from '@/schemas'
import { ACCEPTED_IMAGE_MIME_TYPES, ACCEPTED_IMAGE_TYPES, MAX_IMAGE_SIZE } from '@/constants'

const addressSchemaBasic = zObject({
  city: stringSchema.nullable().optional(),
  country: stringSchema.nullable().optional(),
  postal_code: stringSchema.nullable().optional(),
  state: stringSchema.nullable().optional(),
  street: stringSchema.nullable().optional(),
})

const addressSchema = addressSchemaBasic.extend({
  full_address: stringSchema.optional(),
})

const patientBasicSchema = zObject({
  address: addressSchemaBasic.optional(),
  birthday: stringSchema.optional(),
  category: stringSchema.optional(),
  cell_phone_number: stringSchema.optional(),
  chosen_name: stringSchema.optional(),
  email: stringSchema.email(),
  ethnicity: stringSchema.optional(),
  first_name: stringSchema.optional(),
  gender: stringSchema.optional(),
  home_phone_number: stringSchema.optional(),
  identity_gender: stringSchema.optional(),
  last_name: stringSchema.optional(),
  middle_name: stringSchema.optional(),
  status: stringSchema.optional(),
  website_id: unionSchema([numberSchema, stringSchema]),
})

export const patientSchema = patientBasicSchema.extend({
  id: idSchema,
  address: addressSchema,
  age: numberSchema.nullable().optional(),
  avatar: arraySchema(projectFileSchema).optional(),
  cell_phone_string: stringSchema.optional(),
  home_phone_string: stringSchema.optional(),
  verified_phone: stringSchema.optional(),
  verified_email: stringSchema.optional(),
  name: stringSchema.optional(),
  phone_verified: numberSchema.optional(),
  created_at: stringSchema,
  updated_at: stringSchema,
})

export const patientFormSchema = patientBasicSchema.extend({
  avatar: createMultipleFilesSchema({
    maxSize: MAX_IMAGE_SIZE,
    acceptedMimeTypes: ACCEPTED_IMAGE_MIME_TYPES,
    acceptedTypesDescription: ACCEPTED_IMAGE_TYPES,
  }),
})
