<script setup lang="ts">
import { computed, ref } from 'vue'
import type { Editor } from '@tiptap/vue-3'

import { DropdownMenuCheckboxItem } from '@/ui'
import { ButtonViewReturnComponentProps } from '@/types'
import { ActionDropdownButton, EditorIconType } from '@/components/editor'

function percentageToDecimal(percentageString) {
  const percentage = parseFloat(percentageString.replace('%', ''))
  return percentage / 100
}
const LineHeights = computed(() => {
  const lineHeightOptions = props.editor.extensionManager.extensions.find(e => e.name === 'lineHeight')!.options
  const a = lineHeightOptions.lineHeights
  const b = a.map(item => ({
    label: percentageToDecimal(item),
    value: item,
  }))

  b.unshift({
    label: 'Default',
    value: 'default',
  })
  return b
})

const value = ref('default')
interface Props {
  editor: Editor
  icon?: EditorIconType
  tooltip?: string
  disabled?: boolean
  action?: ButtonViewReturnComponentProps['action']
  isActive?: ButtonViewReturnComponentProps['isActive']
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  tooltip: undefined,
  disabled: false,
  action: undefined,
  isActive: undefined,
})

function toggleLightheight(key: string) {
  if (key === 'default') {
    props.editor.commands.unsetLineHeight()
  } else {
    props.editor.commands.setLineHeight(key)
  }
  value.value = key
}
</script>

<template>
  <ActionDropdownButton :icon="icon" :tooltip="tooltip" :disabled="disabled">
    <DropdownMenuCheckboxItem
      v-for="(item, index) in LineHeights"
      :key="index"
      :checked="item.value === value"
      @click="toggleLightheight(item.value)"
    >
      {{ item.label }}
    </DropdownMenuCheckboxItem>
  </ActionDropdownButton>
</template>
