<script lang="ts" setup>
import { computed } from 'vue'
import type { Editor } from '@tiptap/vue-3'

import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider, DropdownMenuItem } from '@/ui'
import { ActionDropdownButtonSplit } from '@/components/editor'
import { ButtonViewReturnComponentProps } from '@/types'

interface OrderedListOption {
  label: string
  value:
    | 'decimal'
    | 'decimal-leading-zero'
    | 'lower-roman'
    | 'upper-roman'
    | 'lower-latin'
    | 'upper-latin'
    | 'trad-chinese-informal'
    | 'simp-chinese-formal'
}

interface Props {
  editor: Editor
  disabled?: boolean
  tooltip?: string
  shortcutKeys?: string[]
  isActive?: ButtonViewReturnComponentProps['isActive']
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  tooltip: '',
  shortcutKeys: undefined,
  isActive: undefined,
})

const OrderedListOptions: OrderedListOption[] = [
  { label: 'Decimal', value: 'decimal' },
  {
    label: 'Decimal with leading zero',
    value: 'decimal-leading-zero',
  },
  { label: 'Lower Roman', value: 'lower-roman' },
  { label: 'Upper Roman', value: 'upper-roman' },
  { label: 'Lower Latin', value: 'lower-latin' },
  { label: 'Lower Latin', value: 'upper-latin' },
  {
    label: 'Trad Chinese Informal',
    value: 'trad-chinese-informal',
  },
  {
    label: 'Simp Chinese Informal',
    value: 'simp-chinese-formal',
  },
]

const active = computed(() => {
  if (props.editor?.isActive('orderedList')) {
    return props.editor.getAttributes('orderedList').listType
  }
  return 'decimal'
})

function toggleOrderedList(item: OrderedListOption) {
  if (props.editor.isActive('orderedList')) {
    if (props.editor.getAttributes('orderedList').listType === item.value) {
      props.editor.chain()?.toggleOrderedList().run()
    } else {
      props.editor.chain()?.updateAttributes('orderedList', { listType: item.value }).run()
    }
  } else {
    props.editor.chain().focus().toggleOrderedList().updateAttributes('orderedList', { listType: item.value }).run()
  }
}
</script>

<template>
  <ActionDropdownButtonSplit
    :action="toggleOrderedList"
    :disabled="disabled"
    :tooltip="tooltip"
    :is-active="isActive"
    :shortcut-keys="shortcutKeys"
    class="grid w-full min-w-4 grid-cols-3 gap-1"
  >
    <TooltipProvider>
      <Tooltip v-for="item in OrderedListOptions" :key="item.value" :delay-duration="0">
        <TooltipTrigger as-child>
          <DropdownMenuItem class="p-0" @click="toggleOrderedList(item)">
            <div
              :class="[active === item.value ? 'border border-accent-foreground bg-accent' : '']"
              class="box-border flex h-[72px] w-[72px] flex-col rounded-sm border"
            >
              <ol
                :style="{ listStyleType: item.value, lineHeight: 1 }"
                class="flex flex-1 list-outside flex-col items-center justify-center pl-3 text-[12px]"
              >
                <li v-for="i in 3" :key="i">
                  <hr class="my-1 h-[3px] w-6 border-0 bg-gray-200" />
                </li>
              </ol>
            </div>
          </DropdownMenuItem>
        </TooltipTrigger>
        <TooltipContent side="bottom" class="bg-primary text-xs text-primary-foreground">
          {{ item.label }}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  </ActionDropdownButtonSplit>
</template>
