<script lang="ts" setup>
import { Button, Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '@/ui'
import { cn, getShortcutKeys } from '@/utils'
import { ButtonViewReturnComponentProps } from '@/types'
import { EditorIcon, type EditorIconType } from '@/components/editor'

interface Props {
  icon?: EditorIconType
  class?: string
  title?: string
  tooltip?: string
  disabled?: boolean
  shortcutKeys?: string[]
  color?: string
  action?: ButtonViewReturnComponentProps['action']
  isActive?: ButtonViewReturnComponentProps['isActive']
}
const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  title: undefined,
  tooltip: undefined,
  disabled: false,
  color: undefined,
  shortcutKeys: undefined,
  action: undefined,
  isActive: undefined,
  class: '',
})
</script>

<template>
  <TooltipProvider>
    <Tooltip :delay-duration="0">
      <TooltipTrigger as-child>
        <Button :class="cn('h-[32px] px-1.5 py-0', props.class)" variant="ghost" :disabled="disabled">
          <div class="flex h-full w-full items-center justify-between font-normal">
            <div v-if="title" class="flex-grow truncate text-left text-sm">{{ title }}</div>
            <EditorIcon v-if="icon" class="h-[16px] w-[16px]" :name="icon" />
            <EditorIcon class="ml-1 h-3 w-3 flex-shrink-0 text-zinc-500" name="MenuDown" />
          </div>
        </Button>
      </TooltipTrigger>
      <TooltipContent
        v-if="tooltip || (shortcutKeys && shortcutKeys.length)"
        class="bg-primary text-xs text-primary-foreground"
      >
        <div class="flex max-w-24 flex-col items-center text-center">
          <div>{{ tooltip }}</div>
          <div v-if="shortcutKeys && shortcutKeys.length" class="flex">
            <span>{{ getShortcutKeys(shortcutKeys) }}</span>
          </div>
        </div>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
</template>
