<script setup lang="ts">
import { computed } from 'vue'
import { Editor } from '@tiptap/vue-3'

import { DropdownMenuCheckboxItem } from '@/ui'
import { ActionDropdownButton, EditorIcon, type EditorIconType } from '@/components/editor'

interface ContentTypeMenu {
  name: string
  label: string
  iconName: EditorIconType
  action?: (value?: unknown) => void
  isActive: () => boolean
}
interface Props {
  editor: Editor
  disabled?: boolean
  color?: string
  maxHeight?: string | number
  icon?: EditorIconType
  tooltip?: string
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  color: undefined,
  maxHeight: undefined,
  icon: undefined,
  tooltip: '',
  items: () => [],
})

const menus = computed<ContentTypeMenu[]>(() => {
  return [
    {
      name: 'paragraph',
      label: 'Paragraph',
      iconName: 'Paragraph',
      isActive: () =>
        props.editor.isActive('paragraph') &&
        !props.editor.isActive('orderedList') &&
        !props.editor.isActive('bulletList') &&
        !props.editor.isActive('taskList'),
      action: () => props.editor.chain().focus().clearNodes().run(),
    },
    {
      name: 'heading1',
      label: 'Heading 1',
      isActive: () => props.editor.isActive('heading', { level: 1 }),
      iconName: 'Heading1',
      action: () => props.editor.chain().focus().clearNodes().toggleHeading({ level: 1 }).run(),
    },
    {
      name: 'heading2',
      label: 'Heading 2',
      isActive: () => props.editor.isActive('heading', { level: 2 }),
      iconName: 'Heading2',
      action: () => props.editor.chain().focus().clearNodes().toggleHeading({ level: 2 }).run(),
    },
    {
      name: 'heading3',
      label: 'Heading 3',
      isActive: () => props.editor.isActive('heading', { level: 3 }),
      iconName: 'Heading3',
      action: () => props.editor.chain().focus().clearNodes().toggleHeading({ level: 3 }).run(),
    },
    {
      name: 'bulletList',
      label: 'Bullet List',
      isActive: () => props.editor.isActive('bulletList'),
      iconName: 'List',
      action: () => props.editor.chain().focus().clearNodes().toggleBulletList().run(),
    },
    {
      name: 'numberedList',
      label: 'Ordered List',
      isActive: () => props.editor.isActive('orderedList'),
      iconName: 'ListOrdered',
      action: () => props.editor.chain().focus().clearNodes().toggleOrderedList().run(),
    },
    {
      name: 'taskList',
      label: 'Task List',
      isActive: () => props.editor.isActive('taskList'),
      iconName: 'ListTodo',
      action: () => props.editor.chain().focus().clearNodes().toggleTaskList().run(),
    },
    {
      name: 'blockquote',
      label: 'Blockquote',
      isActive: () => props.editor.isActive('blockquote'),
      iconName: 'TextQuote',
      action: () => props.editor.chain().focus().clearNodes().toggleBlockquote().run(),
    },
  ]
})
const activeItem = computed(() => {
  return (
    menus.value.filter(item => item.isActive()).pop() ?? {
      label: 'Modify',
    }
  )
})
</script>

<template>
  <ActionDropdownButton :title="activeItem?.label" :side-offset="5">
    <DropdownMenuCheckboxItem
      v-for="(item, index) in menus"
      :key="index"
      class="cursor-pointer"
      :checked="item.isActive?.() || false"
      @click="item.action"
    >
      <div class="flex items-center gap-2 px-2">
        <EditorIcon :name="item.iconName" class="h3 w-3" />
        <span>{{ item.label }}</span>
      </div>
    </DropdownMenuCheckboxItem>
  </ActionDropdownButton>
</template>
