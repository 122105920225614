import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useEditorStore = defineStore('editor', () => {
  const isFullscreen = ref(false)
  const color = ref<string>()
  const highlight = ref<string>()
  const AIMenu = ref(false)
  const showPreview = ref(false)
  const spellCheck = ref(false)
  const findAndReplace = ref(false)
  const printer = ref(false)

  function toggleFullscreen() {
    isFullscreen.value = !isFullscreen.value
  }

  function togglePreview() {
    showPreview.value = !showPreview.value
  }

  function toggleSpellCheck() {
    spellCheck.value = !spellCheck.value
  }

  function toggleFindAndReplace() {
    findAndReplace.value = !findAndReplace.value
  }

  function togglePrinter() {
    printer.value = !printer.value
  }

  function toggleHighlight(color?: string) {
    highlight.value = color
  }

  return {
    isFullscreen,
    color,
    highlight,
    AIMenu,
    showPreview,
    spellCheck,
    findAndReplace,
    printer,

    toggleFullscreen,
    togglePreview,
    toggleSpellCheck,
    toggleFindAndReplace,
    toggleHighlight,
    togglePrinter,
  }
})
