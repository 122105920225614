<script lang="ts" setup>
import { computed } from 'vue'
import type { Editor } from '@tiptap/vue-3'

import { DropdownMenuCheckboxItem, DropdownMenuSeparator, ScrollArea } from '@/ui'
import { EditorItemType } from '@/types'
import { ActionDropdownButton } from '@/components/editor'

interface Props {
  editor: Editor
  disabled?: boolean
  color?: string
  shortcutKeys?: string[]
  maxHeight?: string | number
  tooltip?: string
  items?: EditorItemType[]
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  color: undefined,
  maxHeight: undefined,
  icon: undefined,
  tooltip: '',
  shortcutKeys: undefined,
  items: () => [],
})

const active = computed(() => {
  const find = props.items.find(k => k.isActive())
  if (find) {
    return find
  }
  const item: EditorItemType = {
    title: 'Default',
    isActive: () => false,
  }
  return item
})
</script>

<template>
  <ActionDropdownButton :disabled="disabled" :tooltip="tooltip" :title="active.title" btn-class="min-w-24 max-w-32">
    <ScrollArea class="h-96 w-32">
      <template v-for="(item, index) in props.items" :key="index">
        <DropdownMenuCheckboxItem :checked="active.title === item.title" @click="item.action">
          <div class="ml-1 h-full">{{ item.title }}</div>
        </DropdownMenuCheckboxItem>
        <DropdownMenuSeparator v-if="item.title === 'Default'" />
      </template>
    </ScrollArea>
  </ActionDropdownButton>
</template>
