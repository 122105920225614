import { numberSchema, stringSchema, urlSchema, zObject } from '@/schemas'

export const projectFileSchema = zObject({
  id: numberSchema,
  name: stringSchema,
  file_name: stringSchema,
  original: urlSchema,
  preview: urlSchema,
  thumb: urlSchema,
})
