import { stringSchema, zObject, booleanSchema, nativeEnumSchema } from '@/schemas'
import { AllergySeverityEnum, AllergyTypeEnum } from '@/types'

export const medicationSchema = zObject({
  id: stringSchema.optional(),
  note: stringSchema,
})

export const allergySchema = zObject({
  id: stringSchema.optional(),
  note: stringSchema.optional(),
  severity: nativeEnumSchema(AllergySeverityEnum).optional(),
  type: nativeEnumSchema(AllergyTypeEnum).optional(),
  adverse_drug_reaction: booleanSchema.optional(),
  description: stringSchema.optional(),
  start_date: stringSchema.optional(),
  end_date: stringSchema.optional(),
})
