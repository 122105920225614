export enum AllergyMedicationEnum {
  Medication = 'medication',
  Allergy = 'allergy',
}

export enum AllergySeverityEnum {
  SEVERE = 'severe',
  MODERATE = 'moderate',
  MILD = 'mild',
}

export enum AllergyTypeEnum {
  DRUG = 'drug',
  INGREDIENT = 'ingredient',
  ALLERGEN = 'allergen',
}

export type AllergyType = {
  id: string
  note: string
  severity: AllergySeverityEnum
  type: AllergyTypeEnum
  description: string
  start_date: string
  end_date: string | null
  adverse_drug_reaction: boolean
  created_at: string
  updated_at: string
}

export type MedicationType = {
  id: string
  note: string
  created_at: string
  updated_at: string
}
