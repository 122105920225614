import { IconNameType } from '@/types'

export const editorIcons: Record<string, IconNameType> = {
  Bold: 'BoldIcon',
  LoaderCircle: 'ArrowPathIcon',
  Italic: 'ItalicIcon',
  Underline: 'UnderlineIcon',
  Quote: 'BeakerIcon',
  TextQuote: 'BeakerIcon',
  Strikethrough: 'StrikethroughIcon',
  Minus: 'MinusIcon',
  Eraser: 'XMarkIcon',
  PaintRoller: 'PaintBrushIcon',
  Redo2: 'ArrowUturnRightIcon',
  Undo2: 'ArrowUturnLeftIcon',
  AlignCenter: 'Bars3Icon',
  AlignJustify: 'Bars4Icon',
  AlignLeft: 'Bars3BottomLeftIcon',
  AlignRight: 'Bars3BottomRightIcon',
  ChevronUp: 'ChevronUpIcon',
  ChevronDown: 'ChevronDownIcon',
  Subscript: 'PencilIcon',
  Superscript: 'PencilIcon',
  Code: 'CodeBracketIcon',
  Code2: 'CodeBracketIcon',
  Type: 'PencilIcon',
  IndentIncrease: 'ArrowRightIcon',
  IndentDecrease: 'ArrowLeftIcon',
  List: 'ListBulletIcon',
  ListOrdered: 'ListBulletIcon',
  ListTodo: 'ListBulletIcon',
  Link: 'LinkIcon',
  ImageUp: 'CameraIcon',
  Video: 'VideoCameraIcon',
  Maximize: 'ArrowsPointingOutIcon',
  Minimize: 'ArrowsPointingInIcon',
  Table: 'BeakerIcon',
  Sparkles: 'SparklesIcon',
  Magic: 'SparklesIcon',
  Pencil: 'PencilIcon',
  Unlink: 'LinkIcon',
  BetweenHorizonalEnd: 'MinusIcon',
  BetweenHorizonalStart: 'MinusIcon',
  ColumnDelete: 'TrashIcon',
  BetweenVerticalStart: 'MinusIcon',
  BetweenVerticalEnd: 'MinusIcon',
  RowDelete: 'TrashIcon',
  TableCellsMerge: 'BeakerIcon',
  TableCellsSplit: 'BeakerIcon',
  Trash2: 'TrashIcon',
  Trash: 'TrashIcon',
  Replace: 'ArrowPathIcon',
  ChevronsUpDown: 'ChevronUpDownIcon',
  LineHeight: 'AdjustmentsVerticalIcon',
  Word: 'DocumentTextIcon',
  Paragraph: 'DocumentTextIcon',
  Heading1: 'DocumentTextIcon',
  Heading2: 'DocumentTextIcon',
  Heading3: 'DocumentTextIcon',
  Columns2: 'ComputerDesktopIcon',
  Columns3: 'ComputerDesktopIcon',
  Columns4: 'ComputerDesktopIcon',
  Plus: 'PlusIcon',
  Grip: 'AdjustmentsVerticalIcon',
  Copy: 'DocumentDuplicateIcon',
  Clipboard: 'ClipboardIcon',
  MenuDown: 'ChevronDownIcon',
  SizeS: 'MinusIcon',
  SizeM: 'MinusIcon',
  SizeL: 'MinusIcon',
  AspectRatio: 'WindowIcon',
  PanelLeft: 'ChevronLeftIcon',
  PanelRight: 'ChevronRightIcon',
  Columns: 'ComputerDesktopIcon',
  AI: 'StarIcon',
  ChevronRight: 'ChevronRightIcon',
  Paste: 'ClipboardIcon',
  Cut: 'ScissorsIcon',
  File: 'DocumentTextIcon',
  Refresh: 'ArrowPathIcon',
  Eye: 'EyeIcon',
  Printer: 'PrinterIcon',
  Tablet: 'DeviceTabletIcon',
  Phone: 'PhoneIcon',
  Monitor: 'ComputerDesktopIcon',
  TextSelect: 'PencilIcon',
  SpellCheck: 'MagnifyingGlassIcon',
  DocSearch: 'MagnifyingGlassIcon',
  Close: 'XMarkIcon',
  ImageSize: 'PhotoIcon',
  FlipVertical: 'ArrowsUpDownIcon',
  FlipHorizontal: 'ArrowsRightLeftIcon',
}
