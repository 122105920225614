import { Extension } from '@tiptap/core'
import type { Extensions } from '@tiptap/core'
import type { SubscriptExtensionOptions as TiptapSubscriptOptions } from '@tiptap/extension-subscript'
import { Subscript as TiptapSubscript } from '@tiptap/extension-subscript'
import type { SuperscriptExtensionOptions as TiptapSuperscriptOptions } from '@tiptap/extension-superscript'
import { Superscript as TiptapSuperscript } from '@tiptap/extension-superscript'

import { EditorItemType, GeneralOptions } from '@/types'

import ActionMoreButton from './components/ActionMoreButton.vue'

export interface MoreMarkOptions extends GeneralOptions<MoreMarkOptions> {
  /**
   *
   * @default true
   */
  subscript: Partial<TiptapSubscriptOptions> | false
  /**
   *
   * @default true
   */
  superscript: Partial<TiptapSuperscriptOptions> | false
}

export const MoreMark = Extension.create<MoreMarkOptions>({
  name: 'moreMark',
  addOptions() {
    return {
      ...this.parent?.(),
      button({ editor, extension }) {
        const subscript = extension.options.subscript
        const superscript = extension.options.superscript
        const subBtn: EditorItemType = {
          action: () => editor.commands.toggleSubscript(),
          isActive: () => editor.isActive('subscript') || false,
          disabled: !editor.can().toggleSubscript(),
          icon: 'Subscript',
          title: 'Subscript',
          shortcutKeys: ['mod', '.'],
        }

        const superBtn: EditorItemType = {
          action: () => editor.commands.toggleSuperscript(),
          isActive: () => editor.isActive('superscript') || false,
          disabled: !editor.can().toggleSuperscript(),
          icon: 'Superscript',
          title: 'Superscript',
          shortcutKeys: ['mod', ','],
        }

        const items: EditorItemType[] = []

        if (subscript !== false) items.push(subBtn)
        if (superscript !== false) items.push(superBtn)

        return {
          component: ActionMoreButton,
          componentProps: {
            icon: 'Type',
            tooltip: 'More Text Styles',
            disabled: !editor.isEditable || items.every(item => item.disabled),
            items,
          },
        }
      },
    }
  },

  addExtensions() {
    const extensions: Extensions = []

    if (this.options.subscript !== false) {
      extensions.push(TiptapSubscript.configure(this.options.subscript))
    }

    if (this.options.superscript !== false) {
      extensions.push(TiptapSuperscript.configure(this.options.superscript))
    }

    return extensions
  },
})
